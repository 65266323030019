@import '~antd/dist/antd.less';
@import '~antd/dist/antd.compact.less'; // 紧凑样式

.lazyLoading {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
@padding-lg: 24px; // containers
@padding-md: 16px; // small containers and buttons
@padding-sm: 12px; // Form controls and items
@padding-xs: 8px; // small items
@padding-xss: 4px; // more small
.text-align-right {
  text-align: right;
}
.page-breadcrumb {
  margin-bottom: 20px;
}
.margin-bottom-md {
  margin-bottom: @padding-md;
}
.margin-top-md {
  margin-top: @padding-md;
}

.modalCloseStyle .ant-modal-close-x {
  width: 30px !important;
  height: 30px !important;
  line-height: 30px !important;
}

@primary-color: #2f70cf;